import * as React from "react";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <main>
      <div className="grid min-h-screen place-items-center bg-stone-800">
        <div>
          <h1 className="text-9xl pb-4 font-bold bg-gradient-to-r from-orange-600 to-orange-900 bg-clip-text text-transparent">
            Page not found
          </h1>
          <p className="text-white">
            <Link to="/">Go back home</Link>
          </p>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
